<template>
    <span
        class="icon"
        :class="{
            'icon-fill-current': fillCurrent,
            'icon-stroke-current': strokeCurrent,
        }"
        v-html="icon"
    />
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        name: string;
        fillCurrent?: boolean;
        strokeCurrent?: boolean;
    }>(),
    {
        fillCurrent: true,
        strokeCurrent: false,
    },
);

const icon = ref('');
const iconsImport = import.meta.glob('assets/svgs/icons/**/**.svg', {
    as: 'raw',
    eager: false,
});
icon.value = await iconsImport[`/assets/svgs/icons/${props.name}.svg`]();
watchEffect(async () => {
    try {
        icon.value =
            await iconsImport[`/assets/svgs/icons/${props.name}.svg`]();
    } catch {
        console.error(
            `[icons] Icon '${props.name}' doesn't exist in 'assets/svgs/icons'`,
        );
    }
});
</script>

<style>
.icon svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-fill-current * {
    fill: currentColor;
}

.icon-stroke-current * {
    stroke: currentColor;
}
</style>
