<template>
    <TransitionRoot :show="isOpen" as="template">
        <Dialog class="relative z-[999999]" @close="onClose">
            <TransitionChild
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-grind/60"></div>
            </TransitionChild>

            <div
                class="fixed inset-0 flex max-h-full items-start justify-center overflow-y-scroll p-4"
            >
                <div
                    class="flex min-h-full w-full items-center justify-center sm:min-w-[512px]"
                >
                    <TransitionChild
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                        class="relative w-full rounded-lg bg-white shadow-card"
                        :class="widthClass"
                    >
                        <DialogPanel
                            class="p-6 pt-5 md:p-8 md:pt-7"
                            data-cy="modal-dialog"
                        >
                            <button
                                v-if="allowClose"
                                aria-label="Close"
                                class="absolute right-6 top-6 z-10 transition-colors hover:text-brew focus:outline-none disabled:pointer-events-none disabled:opacity-70 md:right-8 md:top-8"
                                :disabled="closeDisabled"
                                @click.prevent="onClose"
                            >
                                <Icon name="close" class="text-[20px]" />
                            </button>

                            <div class="z-0">
                                <DialogTitle
                                    v-if="$slots.title"
                                    class="mb-5 mr-10 md:mb-8"
                                    as="h3"
                                >
                                    <slot name="title" />
                                </DialogTitle>

                                <slot />

                                <div
                                    v-if="showActionButtons"
                                    class="actions-container mt-5 flex flex-col-reverse items-center justify-stretch gap-3 md:mt-8 md:flex-row md:justify-end"
                                >
                                    <slot name="actions" :close="close">
                                        <button
                                            class="btn-primary"
                                            @click="close"
                                        >
                                            Close
                                        </button>
                                    </slot>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';

const emit = defineEmits(['self-closed']);

const props = withDefaults(
    defineProps<{
        allowClose?: boolean;
        closeDisabled?: boolean;
        showActionButtons?: boolean;
        widthClass?: string;
    }>(),
    {
        allowClose: true,
        closeDisabled: false,
        showActionButtons: true,
        widthClass: 'max-w-2xl',
    },
);

const isOpen = ref(false);

const open = () => {
    isOpen.value = true;
};

const close = () => {
    isOpen.value = false;
};

const onClose = () => {
    if (props.allowClose && !props.closeDisabled) {
        emit('self-closed');
        close();
    }
};

watch(
    () => isOpen.value,
    (newIsOpen) => {
        if (newIsOpen) {
            document.documentElement.style.scrollBehavior = 'unset';
        } else {
            nextTick(() => {
                document.documentElement.style.scrollBehavior = 'smooth';
            });
        }
    },
);

defineExpose({ open, close });
</script>

<style lang="postcss" scoped>
@media only screen and (max-width: 768px) {
    .actions-container :deep(button) {
        display: block;
        width: 100%;
    }
}
</style>
