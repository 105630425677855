<template>
    <NuxtLayout :name="layoutName">
        <NuxtPage />
        <AlertModal />
        <AddToCartFlyout />
    </NuxtLayout>
</template>

<script setup lang="ts">
import ppcPageRoutes from '~/data/ppcPageRoutes';
import { useSegmentCode } from '~/composables/useSegment';
import AddToCartFlyout from '~/components/cart/AddToCartFlyout.vue';

const route = useRoute();
const { liveChatEnabled } = useRuntimeConfig().public;

const scripts = [];
if (liveChatEnabled) {
    scripts.push({ src: '/js/zopim.js', async: true });
}
useHead({
    script: scripts,
});

const layoutName = computed(() => {
    if (ppcPageRoutes.includes(route.path)) {
        return 'ppc';
    }

    if (route.path === '/product-finder') {
        return 'headless';
    }

    const dedicatedQuotePath = /^\/quotes\/\d+$/;
    if (route.path === '/get-quote' || dedicatedQuotePath.test(route.path)) {
        return 'headless';
    }

    return 'default';
});

onMounted(() => {
    const { user } = useAuth();
    const segmentCode = useSegmentCode(user?.value?.id);

    useHead(() => ({
        bodyAttrs: {
            class: 'nuxt-loaded',
        },
        script: segmentCode,
    }));
});
</script>
