export default defineNuxtPlugin((nuxtApp) => {
    const bugsnagKey = useRuntimeConfig().public.bugsnag.apiKey;

    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
        if (bugsnagKey) {
            useBugsnag().notify(error);
        }
    };

    // Also possible
    nuxtApp.hook('vue:error', (error, instance, info) => {
        if (bugsnagKey) {
            useBugsnag().notify(error);
        }
    });
});
